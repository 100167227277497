import React, {useState} from 'react';
import supabase from 'supabaseClient';
import {useSnackBar} from 'hooks/snackbar';
import {useTranslation} from 'react-i18next';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import QrReader from 'react-qr-reader';
import {
  Box, Button, Chip, Divider, Paper, TextField, Typography,
} from '@mui/material';

const TVLink = ({setAction}) => {
  const [loading, setLoading] = useState(false);
  const [linkPossible, setLinkPossible] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [qr, setQr] = useState('');
  const snack = useSnackBar();
  const {t} = useTranslation();

  const adoptTV = async (text = null) => {
    try {
      setLoading(true);

      const {data, error, status} = await supabase
        .rpc('adopt_tv', {shortkey: text ?? qr});

      if (error && status !== 406) {
        throw error;
      }

      if (data === null) {
        throw new Error(t('tv.link.error'));
      } else {
        snack.success(t('tv.link.success'));
        setAction('');
      }
    } catch (error) {
      snack.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleQr = (text) => {
    if (text !== null && text !== undefined) {
      setQr(text);
      setShowQr(false);
      setLinkPossible(text.length > 5);
      if (text.length > 5) {
        adoptTV(text);
      }
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 2,
        width: {
          xs: 340,
          md: 550,
        },
      }}
    >
      <Typography variant="h5">{t('tv.link.description')}</Typography>
      <Box sx={{height: 30}} />
      <Button
        variant="contained"
        startIcon={<PhotoCameraIcon />}
        disabled={loading}
        onClick={() => setShowQr(!showQr)}
        sx={{
          width: {
            xs: 305,
            md: 515,
          },
        }}
      >
        {t('tv.link.scan_qr')}
      </Button>
      <Box sx={{height: 30}} />

      {showQr && (
        <>
          <Box display="flex" justifyContent="center">
            <QrReader
              delay={200}
              style={{
                height: 295,
                width: 295,
              }}
              onError={(err) => snack.error(err.toString())}
              onScan={(data) => handleQr(data)}
            />
          </Box>
          <Box sx={{height: 30}} />
        </>
      )}
      <Divider>
        <Chip label={t('general.or')} />
      </Divider>
      <Box sx={{height: 30}} />
      <TextField
        id="qr"
        label={t('tv.link.code')}
        variant="standard"
        fullWidth
        value={qr}
        disabled={loading}
        type="text"
        onChange={(e) => handleQr(e.target.value)}
      />
      <Box sx={{height: 30}} />
      <Divider />
      <Box sx={{height: 30}} />
      <Button
        variant="contained"
        disabled={loading || !linkPossible}
        onClick={adoptTV}
        sx={{
          width: {
            xs: 305,
            md: 515,
          },
        }}
      >
        {t('tv.link.device')}
      </Button>
    </Paper>

  );
};

export default TVLink;
