import {useState} from 'react';
import Profile from 'main/profile';
import {Box, IconButton} from '@mui/material';
import TextLogo from 'components/textLogo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TV from 'main/tv';
import TVLink from 'main/tvLink';
import TVDetails from 'main/tvDetails';

const Main = ({session, currAction = ''}) => {
  const [action, setAction] = useState(currAction);

  const showComponent = () => {
    if (action === 'PASSWORD_RECOVERY') {
      return (
        <Profile session={session} setAction={setAction} />
      );
    }

    if (action.startsWith('DETAILS;')) {
      const details = action.split(';');
      return (
        <TVDetails tvId={details[1]} name={details[2]} language={details[3]} portrait={details[4]} setAction={setAction} />
      );
    }

    if (action === 'LINK') {
      return (
        <TVLink setAction={setAction} />
      );
    }

    return (
      <TV setAction={setAction} />
    );
  };

  return (
    <Box display="flex" flexDirection="column" sx={{padding: 1.5}}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{marginBottom: 1.5}}>
        <TextLogo onClick={() => setAction('')} />
        <IconButton color="primary" aria-label="profile" component="label" onClick={() => setAction('PASSWORD_RECOVERY')}>
          <AccountCircleIcon color="disabled" fontSize="large" />
        </IconButton>
      </Box>
      {showComponent()}
    </Box>
  );
};

export default Main;
