import {Box, ButtonBase} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

const TextLogo = ({onClick}) => {
  const {t} = useTranslation();

  return (
    <ButtonBase onClick={onClick}>
      <Box justifyContent="center" alignItems="center" alignSelf="center" alignContent="center">
        <Typography variant="title_1">{t('title_1')}</Typography>
        <Typography variant="title_2">{t('title_2')}</Typography>
      </Box>
    </ButtonBase>
  );
};

export default TextLogo;
