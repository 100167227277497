import React, {useState} from 'react';
import supabase from 'supabaseClient';
import {useTranslation} from 'react-i18next';
import {useSnackBar} from 'hooks/snackbar';
import {
  Box, Button, CircularProgress, Paper, TextField,
} from '@mui/material';
import LanguageSwitch from 'components/languageSwitch';
import TextLogo from 'components/textLogo';

const Auth = () => {
  const [action, setAction] = useState('sign_in');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const snack = useSnackBar();
  const {t} = useTranslation();

  const handleSignIn = async () => {
    try {
      setLoading(true);

      const {error} = await supabase.auth.signInWithPassword({email, password});
      if (error) throw error;
    } catch (error) {
      if (error.message === 'Email not confirmed') {
        snack.error(t('auth.email_confirm'));
      } else {
        snack.error(error.error_description || error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    try {
      setLoading(true);

      if (password !== password2) {
        throw new Error(t('auth.password_conflict'));
      }
      const {error} = await supabase.auth.signUp(
        {
          email,
          password,
          options: {
            data: t('mail.confirm', {returnObjects: true}),
          },
        },
      );
      if (error) throw error;
      snack.success(`${t('auth.sign_up_complete')}. ${t('auth.email_confirm')}`);
      setAction('sign_in');
    } catch (error) {
      snack.error(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);

      const {error} = await supabase.auth.resetPasswordForEmail(
        email,
      );

      if (error) throw error;
      snack.success(`${t('auth.password_forgotten_complete')}. ${t('auth.email_confirm')}`);
    } catch (error) {
      snack.error(error.error_description || error.message);
    } finally {
      setLoading(false);
      setAction('sign_in');
    }
  };

  const handleAuth = async () => {
    if (action === 'sign_in') {
      handleSignIn();
    } else if (action === 'sign_up') {
      handleSignUp();
    } else if (action === 'password_reset') {
      handleResetPassword();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Paper
        variant="outlined"
        sx={{
          padding: 2,
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextLogo />
          <LanguageSwitch />
        </Box>

        <Box
          component="form"
          sx={{
            '& > :not(style)': {m: 1, width: '25ch'},
          }}
          noValidate
          autoComplete="on"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {action !== 'new_password' && (
            <TextField
              id="email"
              label={t('auth.email')}
              variant="standard"
              value={email}
              disabled={loading}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          )}

          {action !== 'password_reset' && (
            <TextField
              id="password"
              label={t('auth.password')}
              variant="standard"
              value={password}
              disabled={loading}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          )}

          {action === 'sign_up' && (
          <TextField
            id="password2"
            label={t('auth.password_repeat')}
            variant="standard"
            value={password2}
            disabled={loading}
            type="password"
            onChange={(e) => setPassword2(e.target.value)}
          />
          )}

          <Button
            variant="contained"
            disabled={loading}
            onClick={handleAuth}
          >
            {t(`auth.${action}`)}
          </Button>

          {action !== 'sign_up' && (
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => setAction('sign_up')}
            >
                {t('auth.new_user')}
            </Button>
          )}

          {action !== 'sign_in' && (
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => setAction('sign_in')}
          >
            {t('auth.existing_user')}
          </Button>
          )}

          {action !== 'password_reset' && (
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => setAction('password_reset')}
            >
              {t('auth.password_forgotten')}
            </Button>
          )}

        </Box>
      </Paper>
      {loading && (
      <CircularProgress
        size={64}
        sx={{
          color: 'gray',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-32px',
          marginLeft: '-32px',
        }}
      />
      )}
    </Box>
  );
};

export default Auth;
