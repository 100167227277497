import {
  createContext, useContext, useMemo, useState,
} from 'react';
import SnackBar from 'context/snackbar';

export const SnackBarContext = createContext({});

export const SnackBarProvider = ({
  children,
}) => {
  const [messages, setMessages] = useState([]);

  const removeMessage = (key) => setMessages((arr) => arr.filter((m) => m.key !== key));

  return (
    <SnackBarContext.Provider
      value={useMemo(() => ({
        addMessage(message) {
          setMessages((arr) => [...arr, message]);
        },
      }), [])}
    >
      {children}
      {messages.map((m) => (
        <SnackBar
          key={m.key}
          message={m}
          onExited={() => removeMessage(m.key)}
        />
      ))}
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = () => {
  const {addMessage} = useContext(SnackBarContext);

  const show = (message, options) => {
    addMessage({message, ...options, key: new Date().getTime()});
  };

  return {
    show,
    info(message) {
      show(message, {severity: 'info'});
    },
    success(message) {
      show(message, {severity: 'success'});
    },
    warning(message) {
      show(message, {severity: 'warning'});
    },
    error(message) {
      show(message, {severity: 'error'});
    },
  };
};
