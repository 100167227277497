import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import {useState, forwardRef} from 'react';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const SlideTransition = (props) => <Slide {...props} direction="right" />;

const SnackBar = ({
  message,
  onExited,
  autoHideDuration,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    _event,
    reason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      TransitionProps={{onExited}}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      autoHideDuration={autoHideDuration ?? 5000}
    >
      <Alert severity={message.severity}>{message.message}</Alert>
    </Snackbar>
  );
};

export default SnackBar;
