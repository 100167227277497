import {useTranslation} from 'react-i18next';
import {Box, Button} from '@mui/material';

const LanguageSwitch = () => {
  const {i18n} = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Box>
      <Button
        variant={i18n.language.startsWith('en') ? 'outlined' : 'text'}
        onClick={() => changeLanguageHandler('en')}
      >
        EN
      </Button>
      {' | '}
      <Button
        variant={i18n.language.startsWith('de') ? 'outlined' : 'text'}
        onClick={() => changeLanguageHandler('de')}
      >
        DE
      </Button>
    </Box>
  );
};

export default LanguageSwitch;
