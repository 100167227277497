import React from 'react';
import {createRoot} from 'react-dom/client';
import {SnackBarProvider} from 'hooks/snackbar';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import './i18n';
import App from './App';
import '@fontsource/work-sans/300.css';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/700.css';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: '#4A7B9B',
    },
    icon_black: {
      main: '#0F172A',
    },
  },
  typography: {
    fontFamily: 'Work Sans, Arial, sans-serif',
    title_1: {
      color: '#414141',
      fontSize: 32,
      fontWeight: 700,
    },
    title_2: {
      color: '#414141',
      fontSize: 32,
      fontWeight: 500,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {borderRadius: 8},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 48,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // fontSize: 12,
        },
      },
    },
  },

});

createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackBarProvider>
      <App />
    </SnackBarProvider>
  </ThemeProvider>,
);
