import {useState, useEffect} from 'react';
import Auth from 'auth';
import Main from 'main';
import supabase from 'supabaseClient';

const App = () => {
  const [userSession, setUserSession] = useState(null);
  const [currAction, setCurrAction] = useState(undefined);

  useEffect(() => {
    supabase.auth.getSession().then(({data: {session}}) => {
      setUserSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setUserSession(session);
      setCurrAction(_event);
    });
  }, []);

  return userSession ? (
    <Main session={userSession} currAction={currAction} />
  ) : (
    <Auth />
  );
};

export default App;
