import React, {useState, useEffect} from 'react';
import supabase from 'supabaseClient';
import {useSnackBar} from 'hooks/snackbar';
import {useTranslation} from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box, Button, IconButton, Paper, Typography,
} from '@mui/material';

const TV = ({setAction}) => {
  const [loading, setLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [tvs, setTVs] = useState([]);
  const snack = useSnackBar();
  const {t} = useTranslation();

  const getTVs = async () => {
    try {
      setLoading(true);

      const {data, error, status} = await supabase
        .from('tvs')
        .select('id,name,language,portrait');

      if (error && status !== 406) {
        throw error;
      }

      if (data?.length > 0) {
        setTVs(data);
      } else {
        setAction('LINK');
      }
    } catch (error) {
      snack.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteTV = async () => {
    // TODO
    try {
      setLoading(true);

      const {error} = await supabase
        .from('tvs')
        .delete()
        .eq('id', tvs[showDeleteDialog]?.id);

      if (error) {
        throw error;
      }
      getTVs();
    } catch (error) {
      snack.error(error.message);
    } finally {
      setLoading(false);
      setShowDeleteDialog(false);
    }
  };

  useEffect(() => {
    getTVs();
  }, []);

  return (
    <>
      {tvs.map((tv, index) => (
        <React.Fragment key={`tv_${tv.id}`}>
          <Paper
            variant="outlined"
            sx={{
              padding: 2,
              width: {
                xs: 340,
                md: 550,
              },
            }}
          >
            <Typography variant="h5">{tv.name}</Typography>
            <Box sx={{height: 30}} />
            <Box
              display="flex"
              sx={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  width: 50,
                  height: 50,
                  backgroundColor: '#D9D9D9',
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    color="icon_black"
                    aria-label="profile"
                    component="label"
                    onClick={() => setAction(`DETAILS;${tv.id};${tv.name};${tv.language};${tv.portrait}`)}
                  >
                    <EditIcon
                      color="icon_black"
                      fontSize="medium"
                    />
                  </IconButton>
                </Box>

              </Paper>
              <Box sx={{width: 10}} />
              <Paper
                variant="outlined"
                sx={{
                  width: 50,
                  height: 50,
                  backgroundColor: '#D9D9D9',
                }}
              >
                <Box
                  display="flex"
                  sx={{
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton color="icon_black" aria-label="profile" component="label" onClick={() => setShowDeleteDialog(index)}>
                    <DeleteOutlineIcon
                      color="icon_black"
                      fontSize="medium"
                    />
                  </IconButton>

                </Box>

              </Paper>
            </Box>
          </Paper>
          <Box sx={{height: 20}} />
        </React.Fragment>
      ))}
      <Button
        variant="contained"
        disabled={loading}
        onClick={() => setAction('LINK')}
        sx={{
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        {t('tv.link.device')}
      </Button>
      <Dialog
        open={showDeleteDialog !== false}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('tv.overview.delete_title_1')}
          {' '}
          {tvs[showDeleteDialog]?.name}
          {' '}
          {t('tv.overview.delete_title_2')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('tv.overview.delete_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} autoFocus>
            {t('general.cancel')}
          </Button>
          <Button color="error" onClick={deleteTV}>{t('general.delete')}</Button>

        </DialogActions>
      </Dialog>

    </>
  );
};

export default TV;
