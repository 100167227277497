import {useState} from 'react';
import supabase from 'supabaseClient';
import {useSnackBar} from 'hooks/snackbar';
import {useTranslation} from 'react-i18next';
import {
  Box, Button, CircularProgress, Paper, TextField, Typography, FormControl, Select, InputLabel, MenuItem,
} from '@mui/material';

const Profile = ({session}) => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState(session.user.email);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [language, setLanguage] = useState(i18n.language.split('-')[0]);
  const snack = useSnackBar();

  const handleNewMail = async () => {
    try {
      setLoading(true);

      const {error} = await supabase.auth.updateUser({
        email: mail,
      });
      if (error) throw error;
      snack.success(t('auth.email_reset_complete'));
    } catch (error) {
      snack.error(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNewPassword = async () => {
    try {
      setLoading(true);

      if (password1 !== password2) {
        throw new Error(t('auth.password_conflict'));
      }
      const {error} = await supabase.auth.updateUser({
        password: password1,
      });
      if (error) throw error;
      snack.success(t('auth.password_reset_complete'));
    } catch (error) {
      snack.error(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeLanguage = () => {
    i18n.changeLanguage(language);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5">{t('profile.section_language')}</Typography>
      <Box sx={{height: 10}} />
      <Paper
        variant="outlined"
        sx={{
          padding: 2,
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="language-input">{t('general.language')}</InputLabel>
          <Select
            labelId="language-label"
            id="language"
            value={language}
            label={t('general.language')}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <MenuItem value="de">{t('general.de')}</MenuItem>
            <MenuItem value="en">{t('general.en')}</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{height: 10}} />
        <Button variant="contained" disabled={loading} onClick={handleChangeLanguage}>{t('profile.section_language')}</Button>
      </Paper>
      <Box sx={{height: 30}} />

      <Typography variant="h5">{t('profile.section_email')}</Typography>
      <Box sx={{height: 10}} />
      <Paper
        variant="outlined"
        sx={{
          padding: 2,
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        <TextField
          id="email"
          label={t('auth.email')}
          variant="standard"
          fullWidth
          value={mail}
          disabled={loading}
          type="email"
          onChange={(e) => setMail(e.target.value)}
        />
        <Box sx={{height: 10}} />
        <Button variant="contained" disabled={loading} onClick={handleNewMail}>{t('auth.email_reset')}</Button>
      </Paper>
      <Box sx={{height: 30}} />

      <Typography variant="h5">{t('profile.section_password')}</Typography>
      <Box sx={{height: 10}} />

      <Paper
        variant="outlined"
        sx={{
          padding: 2,
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        <TextField
          id="password1"
          label={t('auth.password')}
          variant="standard"
          fullWidth
          value={password1}
          disabled={loading}
          type="password"
          onChange={(e) => setPassword1(e.target.value)}
        />
        <Box sx={{height: 10}} />
        <TextField
          id="password2"
          label={t('auth.password_repeat')}
          variant="standard"
          fullWidth
          value={password2}
          disabled={loading}
          type="password"
          onChange={(e) => setPassword2(e.target.value)}
        />
        <Box sx={{height: 10}} />
        <Button variant="contained" disabled={loading} onClick={handleNewPassword}>{t('auth.password_reset')}</Button>
      </Paper>
      <Box sx={{height: 30}} />

      <Button
        variant="contained"
        disabled={loading}
        onClick={() => supabase.auth.signOut()}
        sx={{
          width: {
            xs: 340,
            md: 550,
          },
        }}
      >
        {t('auth.sign_out')}
      </Button>

      {loading && (
      <CircularProgress
        size={64}
        sx={{
          color: 'gray',
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-32px',
          marginLeft: '-32px',
        }}
      />
      )}
    </Box>
  );
};

export default Profile;
