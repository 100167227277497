import React, {useState, useEffect} from 'react';
import {
  Paper,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import supabase from 'supabaseClient';
import {useSnackBar} from 'hooks/snackbar';
import {useTranslation} from 'react-i18next';

const TVDetails = ({
  tvId, name, language, portrait,
}) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(true);
  const snack = useSnackBar();
  const {i18n, t} = useTranslation();

  const getTV = async () => {
    try {
      setLoading(true);

      const {data, error, status} = await supabase
        .from('module_tv')
        .select(`
        slot,
        config,
        module: modules(
          active,
          name,
          description, 
          config
        )
        `)
        .eq('tv_id', tvId);

      if (error && status !== 406) {
        throw error;
      }
      const tmp = {};

      data.forEach((tv) => {
        if (tv.module.active) {
          tmp[tv.slot] = tmp[tv.slot] || [];
          const module = tv.config ?? tv.module.config;
          module.name = tv.module.name;
          module.description = tv.module.description;
          tmp[tv.slot].push(module);
        }
      });
      setConfig(tmp);
      console.log('🚀 ~ file: index.js ~ line 45 ~ getTV ~ tmp', tmp);
    } catch (error) {
      snack.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTV();
  }, []);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 2,
        width: {
          xs: 340,
          md: 550,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{t('general.title')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">{name}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{t('general.language')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              id={t(language)}
              value={t(language)}
              label={t(language)}
              // onChange={}
            >
              {i18n.languages.map((lng) => (
                <MenuItem value={t(lng)} key={lng}>{t(`general.${lng}`)}</MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{t('general.portrait')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">{portrait}</Typography>
        </Grid>
      </Grid>

      <Typography variant="h5">
        {JSON.stringify(config)}
      </Typography>
    </Paper>
  );
};

export default TVDetails;
