import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import supabase from 'supabaseClient';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    detection: {
      order: ['navigator'],
    },
    debug: false,
    fallbackLng: ['en', 'de'],
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '{{lng}}',
      request: (options, url, payload, callback) => {
        try {
          supabase
            .from('translation')
            .select('resources')
            .single()
            .then(({data, error}) => {
              callback(error, {
                data: data.resources[url].translation,
                status: 200,
              });
            });
        } catch (error) {
          callback(error.error_description || error.message, null);
        }
      },
    },
  });

// i18n.changeLanguage('en'); // manual switch for language debugging

export default i18n;
